import { createRouter, createWebHistory } from "vue-router";
import ブログ一覧 from "@/views/ブログ一覧.vue";

const router = createRouter({
  // routesは path: encodeURI("/ログイン"), とすると日本語でも再読み込み時にちゃんと表示される。
  // modeやbaseやNginx側の設定は無関係。
  mode: 'history',
  base: process.env.BASE_URL,
  history: createWebHistory(),
  routes: [
    {
      path: encodeURI("/"),
      name: "ホーム",
      component: () => import("@/views/ホーム.vue"),
    },
    {
      path: encodeURI("/登録"),
      name: "登録",
      component: () => import("@/views/登録.vue"),
    },
    {
      path: encodeURI("/本登録"),
      name: "本登録",
      component: () => import("@/views/本登録.vue"),
    },
    {
      path: encodeURI("/ログイン"),
      name: "ログイン",
      component: () => import("@/views/ログイン.vue"),
    },
    {
      path: encodeURI("/マイページ"),
      name: "マイページ",
      component: () => import("@/views/マイページ.vue"),
    },
    {
      path: encodeURI("/自己分析"),
      name: "自己分析",
      component: () => import("@/views/自己分析.vue"),
    },
    {
      path: encodeURI("/自己分析/学生さん"),
      name: "学生さん",
      component: () => import("@/views/自己分析/学生さん.vue"),
    },
    { 
      path: encodeURI("/blogs"), 
      name: "ブログ一覧", 
      component: () => import("@/views/ブログ一覧.vue"),
    },
    { 
      path: encodeURI("/blogs/"), 
      component: () => import("@/views/ブログ一覧.vue"),
    },
    { 
      path: encodeURI("/blogs/:catchAll(.*)"),
      component: () => import("@/views/ブログ詳細.vue"),
    },
    {
      path: encodeURI("/管理画面/ブログ"),
      name: "管理画面_ブログ一覧", 
      component: () => import("@/views/管理画面/ブログ一覧.vue"),
    },
    {
      path: encodeURI("/管理画面/ブログ/"),
      component: () => import("@/views/管理画面/ブログ一覧.vue"),
    },
    {
      path: encodeURI("/管理画面/ブログ/:catchAll(.*)"),
      component: () => import("@/views/管理画面/ブログ詳細.vue"),
    },

  ],
});

// ------------------------------------------------------------------------------------------------------------------------------

router.afterEach((遷移先, 遷移元) => {
  if (遷移先.path !== 遷移元.path) {
    // window.ga('set', 'page', 遷移先.path);
    // window.ga('send', 'pageview');
    canonicalにURLを設定(location.origin + 遷移先.path)
  }
  
  // router側にこう入れても動かない
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // },
  window.scrollTo(0, 0);
})

function canonicalにURLを設定(url){
  const links = document.getElementsByTagName('link')
  for (var i = 0; i < links.length; i++) {
    if (links[i].rel.toLowerCase() === 'canonical') {
      links[i].href = url
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------------------

export default router;
