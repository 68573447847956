<template>
  <template v-if="to && to.charAt(0) === '/' ? true : false">
    <router-link :to="to"><button :class="class" v-html="text"></button></router-link>
  </template>
  <template v-else-if="to && to.charAt(0) !== '/' ? true : false && page">
    <router-link :to="{ name: to, query: {p: page} }"><button :class="class" v-html="text"></button></router-link>
  </template>
  <template v-else-if="to && to.charAt(0) !== '/' ? true : false && !page">
    <router-link :to="{ name: to }"><button :class="class" v-html="text"></button></router-link>
  </template>
  <template v-else-if="href">
    <a :href="href"><button :class="class" v-html="text"></button></a>
  </template>
  <template v-else>
    <button :class="class" v-html="text"></button>
  </template>
</template>

<script>
// class でLintエラーが起きるが、問題なく動作はする
export default {
  props: ['to', 'href', 'text', 'class', 'page']
}
</script>

<style scoped>
button {
  @apply whitespace-nowrap justify-center px-6 pt-2 pb-3 text-slate-600
}
</style>
