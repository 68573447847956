<template>
  <transition enter-active-class="duration-75 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-75 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
    <PopoverPanel focus class="absolute z-10 h-screen top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
      <div class="h-screen rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div class="pt-5 pb-6 px-5">
          <div class="flex items-center justify-between">
            <div class="font-bold">
              ハウカフェジョブα版
            </div>
            <div class="-mr-2">
              <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-teal-700 hover:bg-gray-50 focus:outline-none ring-2 hover:ring-4 focus:ring-inset focus:ring-teal-700">
                <span class="sr-only">メニューを閉じる</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </PopoverButton>
            </div>
          </div>
        </div>
        <div class="py-6 px-5 space-y-10">

          <div>
              <h3 class="mt-0">特に大事にしている想い🔥</h3>
              <ul role="list" class="mt-4 space-y-4 pl-0">
                <li v-for="ブログ in 特に大事にしていること" :key="ブログ.id" class="text-base truncate pl-0">
                  <a :href="ブログ.URL" class="">
                    {{ ブログ.題名 }}
                  </a>
                </li>
              </ul>
              <div class="mt-10">
                <a href="/blogs" class=""> すべての想いを読む <span aria-hidden="true">&rarr;</span></a>
              </div>
          </div>

          <div>
            <div v-if="ログイン状態 == '会員'">
              <V枠線ボタン to="マイページ" text="マイページ" class="w-full"></V枠線ボタン>
            </div>
            <div v-else-if="ログイン状態 == 'お試し'">
              <Vテキストボタン :text="会員ID" class="ml-0 !p-0 cursor-default w-full"></Vテキストボタン>
              <Vテキストボタン text="お試し中" class="cursor-default w-full"></Vテキストボタン>
              <V色付きボタン to="本登録" text="登録して保存する" class="w-full"></V色付きボタン>
            </div>
            <div v-else>
              <V色付きボタン to="登録" text="未登録の方はここで登録" class="w-full mb-4"></V色付きボタン>
              <V枠線ボタン to="ログイン" text="ログイン" class="w-full"></V枠線ボタン>
            </div>
          </div>

        </div>
      </div>
    </PopoverPanel>
  </transition>
</template>

<script setup>
import V枠線ボタン from '../ボタン/枠線ボタン.vue';
import V色付きボタン from '../ボタン/色付きボタン.vue';
import Vテキストボタン from '../ボタン/テキストボタン.vue';
import { ref } from 'vue'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { 特に大事にしていること } from '@/main';

const 会員ID = ref('')
const ログイン状態 = ref('')

onAuthStateChanged(getAuth(), function(会員) {
  if (会員 && 会員.isAnonymous == true) {
    会員ID.value = 会員.uid.slice(0,4)
    ログイン状態.value = 'お試し'
  } else if (会員 && 会員.isAnonymous === false) {
    会員ID.value = 会員.id.slice(0,4)
    ログイン状態.value = '会員'
  } else {
    ログイン状態.value = ''
  }
})
</script>
