<template>
  <div class="lg:flex">
    <main class="w-full">
      <div class="flex-col mx-auto lg:pr-16">
        <h1 class="mb-16">想い一覧</h1>
        <ul class="text-left p-0 rounded-lg flex flex-col list-none w-fit m-0">
          <li v-for="ブログ in 特に大事にしていること" :key="ブログ.id" class="text-sm md:text-base pl-0 py-1">
            <a :href="ブログ.URL" class="">
              <span v-if="ブログ.公開範囲" class="inline-flex items-center rounded-full bg-purple-100 px-2.5 pt-[0.05rem] pb-[0.125rem] text-xs text-purple-800 mr-1">{{ ブログ.公開範囲 }}</span>
              {{ ブログ.題名 }}
            </a>
          </li>
        </ul>
      </div>
    </main>
    <aside class="w-full lg:w-[36rem] lg:border-l lg:pl-8">
      <V右ペイン/>
    </aside>
  </div>
</template>

<script setup>
  import V右ペイン from '@/components/全ページ共通/本体/右ペイン.vue'
  import { ref } from 'vue'
  import { firestore } from '@/main'
  import { collection, query, where, orderBy, getDocs } from "firebase/firestore"

  const 特に大事にしていること = ref([])
  getDocs(
    query(
      collection(firestore, "ブログ"), 
      where("公開日時", "<", new Date()),
    )
  )
    .then((リスト) => {
      リスト.forEach((ブログ) => {
        特に大事にしていること.value.push( {
          id: ブログ.id,
          URL: "/blogs/" + ブログ.id,
          題名: ブログ.data().題名,
          本文: ブログ.data().本文,
          重要度: ブログ.data().重要度,
          公開範囲: ブログ.data().公開範囲,
        } );
      });
      特に大事にしていること.value.sort((a, b) => b.重要度 - a.重要度)
    })
  .catch((err) => {
    console.log(err)
  });
</script>