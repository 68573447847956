<template>
  <template v-if="to && to.charAt(0) === '/' ? true : false">
    <router-link :to="to"><button :class="class" v-html="text + 加工済みsubtext"></button></router-link>
  </template>
  <template v-else-if="to && to.charAt(0) !== '/' ? true : false">
    <router-link :to="{ name: to }"><button :class="class" v-html="text + 加工済みsubtext"></button></router-link>
  </template>
  <template v-else-if="href">
    <a :href="href"><button :class="class" v-html="text + 加工済みsubtext"></button></a>
  </template>
  <template v-else>
    <button :class="class" v-html="text + 加工済みsubtext"></button>
  </template>
</template>

<script>

// :to と {{}} で書き方が変わる
// https://v3.router.vuejs.org/ja/api/#v-slot-api-3-1-0-%E4%BB%A5%E9%99%8D
// toがあれば <router-link> で、hrefがあれば <a> で囲む
// class でLintエラーが起きるが、問題なく動作はする
export default {
  props: ['to', 'href', 'text', 'subtext', 'class'],
  computed: {
    加工済みsubtext() {
      const subtext = this.subtext || ""
      if (subtext !== "") {
        return `<small class="block mt-1 md:mt-2 text-slate-300">${subtext}</small>`
      } else {
        return ""
      }  
    }
  }
}
</script>

<style scoped>
button {
  @apply whitespace-nowrap justify-center px-6 pt-2 pb-3 ring-2 ring-teal-700 hover:ring-4 rounded-md bg-white hover:bg-gray-50 shadow-md text-teal-800 drop-shadow
}
</style>
