<template>
  <!-- メニュー全体 -->
  <Popover class="relative bg-white">

    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div class="flex justify-between items-center border-b border-slate-100 py-6 md:justify-start md:space-x-10 text-base md:text-sm lg:text-base">
        <div class="flex justify-start">
          <router-link to="/">
            <span class="font-bold">ハウカフェジョブα版</span>
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
            <span class="sr-only">メニューを開く</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden md:flex space-x-10">
          <VPC想いポップアップ />
          <template v-if="ログイン状態 && 保存済みのページ番号 > 0">
          </template>
          <template v-else>
            <router-link :to="{ name: '自己分析'}" class="hidden md:flex"> 無料でお試し </router-link>
          </template>
        </PopoverGroup>

        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <template v-if="ログイン状態 == '会員'">
            <router-link :to="{ name: 'マイページ'}" class="whitespace-nowrap text-gray-500 hover:text-gray-900"> マイページ </router-link>
          </template>
          <template v-else-if="ログイン状態 == 'お試し'">
            
            <Vテキストボタン text="お試し中" class="cursor-default"></Vテキストボタン>
            <Vテキストボタン :text="会員ID" class="mr-4 cursor-default"></Vテキストボタン>

            <template v-if="保存済みのページ番号 > 0">
              <V色付きボタン to="学生さん" :page="保存済みのページ番号" text='前回進めたところまで飛ぶ<svg class="w-5 h-5 ml-2 -mr-1 inline-block mt-[0.16rem]" fill="currentColor" viewBox="0 0 20 26" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>' class="text-xs !pb-1.5" />
            </template>

          </template>
          <template v-else>
            <V枠線ボタン to="ログイン" text="ログイン" class="mr-4"></V枠線ボタン>
            <V色付きボタン to="自己分析" text="まずは無料で試す"></V色付きボタン>
          </template>
        </div>
      </div>
    </div>

    <Vスマホメニューポップアップ />

  </Popover>
</template>

<script setup>
  import Vスマホメニューポップアップ from './スマホメニューポップアップ.vue';
  import VPC想いポップアップ from './PC想いポップアップ.vue';
  import V枠線ボタン from '../ボタン/枠線ボタン.vue';
  import V色付きボタン from '../ボタン/色付きボタン.vue';
  import Vテキストボタン from '../ボタン/テキストボタン.vue';
  import { ref } from 'vue'
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
  import {
    BookmarkAltIcon,
    CalendarIcon,
    ChartBarIcon,
    CursorClickIcon,
    MenuIcon,
    PhoneIcon,
    PlayIcon,
    RefreshIcon,
    ShieldCheckIcon,
    SupportIcon,
    ViewGridIcon,
    XIcon,
  } from '@heroicons/vue/outline'
  import { doc, getDoc } from "firebase/firestore"
  import { firestore } from '@/main'

  const 会員ID = ref('')
  const ログイン状態 = ref('')
  const カテゴリー = ref('')
  const 保存済みのページ番号 = ref(0)

  onAuthStateChanged(getAuth(), function(会員) {
    if (会員 && 会員.isAnonymous == true) {
      会員ID.value = 会員.uid.slice(0,4)
      ログイン状態.value = 'お試し'
      保存済みのデータを取得(会員).then(() => {
      })
    } else if (会員 && 会員.isAnonymous == false) {
      会員ID.value = 会員.id.slice(0,4)
      ログイン状態.value = '会員'
      保存済みのデータを取得(会員).then(() => {
      })
    } else {
      ログイン状態.value = ''
    }
  })

  const 保存済みのデータを取得 = (会員) => {
    return new Promise((resolve, reject) => {
      getDoc(doc(firestore, "users", 会員.uid, "分析", "1"))
        .then((分析ドキュメント) => {
          カテゴリー.value = 分析ドキュメント.data().カテゴリー
          保存済みのページ番号.value = 分析ドキュメント.data().ページ番号 ? 分析ドキュメント.data().ページ番号 : 0
          return resolve()
        })
        .catch((エラー) => {
          return resolve()
        })
    })
  }
</script>
