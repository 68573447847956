<template>
  <template v-if="to && to.charAt(0) === '/' ? true : false">
    <router-link :to="to"><button :class="class" v-html="テキスト"></button></router-link>
  </template>
  <template v-else-if="to && to.charAt(0) !== '/' ? true : false && page">
    <router-link :to="{ name: to, query: {p: page} }"><button :class="class" v-html="テキスト"></button></router-link>
  </template>
  <template v-else-if="to && to.charAt(0) !== '/' ? true : false && !page">
    <router-link :to="{ name: to }"><button :class="class" v-html="テキスト"></button></router-link>
  </template>
  <template v-else-if="href">
    <a :href="href"><button :class="class" v-html="テキスト"></button></a>
  </template>
  <template v-else>
    <button :class="class" v-html="テキスト"></button>
  </template>
</template>

<script>
// class でLintエラーが起きるが、問題なく動作はする
export default {
  props: ['to', 'href', 'text', 'class', 'page', 'arrow'],
  computed: {
    テキスト() {
      if(this.arrow == 'true') {
        return this.text + '<svg class="w-5 h-5 ml-2 -mr-1 inline-block" fill="currentColor" viewBox="0 0 20 26" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
      }
      else {
        return this.text;
      }
    }
  }
}
</script>

<style scoped>
button {
  @apply whitespace-nowrap justify-center px-6 pt-2 pb-2.5 ring-2 ring-teal-800 hover:ring-4 rounded-md bg-teal-800 hover:bg-teal-700 shadow-md text-white
}
</style>
