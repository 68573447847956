// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};
const firebaseApp = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 1000;
const firestore = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);

import { ref, createApp } from 'vue'
import App from './views/App.vue'
import router from "./router";
import './index.css'
import { collection, query, where, orderBy, getDocs, startAt } from "firebase/firestore";

const 特に大事にしていること = ref([])
getDocs(
  query(
    collection(firestore, "ブログ"), 
    where("特に大事にしていることリストに出すか", "==", true),
    where("公開日時", "<", new Date()),
  )
)
  .then((リスト) => {
    リスト.forEach((ブログ) => {
      特に大事にしていること.value.push( {
        id: ブログ.id,
        URL: "/blogs/" + ブログ.id,
        題名: ブログ.data().題名,
        本文: ブログ.data().本文,
        重要度: ブログ.data().重要度,
        公開範囲: ブログ.data().公開範囲,
      } );
    });
    特に大事にしていること.value.sort((a, b) => b.重要度 - a.重要度)
  })
.catch((err) => {
  console.log(err)
});

// 定義はこちらが後
const app = createApp(App);
app.use(router);
app.mount("#app");

export { remoteConfig, firestore, 特に大事にしていること };
